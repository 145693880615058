import React, { useState } from "react";
import {
  Dialog,
  Box,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useEffect } from "react";
import inProgress from "../../../assets/Done.svg";

const ModalDispatched = ({ open, onClose, onConfirm, loading }) => {
  useEffect(() => {
    console.log("InProgress");
  }, []);

  const handleConfirmClick = async () => {
    await onConfirm();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paper": {
          width: "500px",
          height: "400px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "center",
          fontWeight: "bold",
        }}
      >
        {"CONFIRMA EL PEDIDO DESPACHADO"}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <Box
        component="img"
        src={inProgress}
        alt="Preparando"
        sx={{
          width: "350px",
          height: "400px",
          margin: "16px 0",
        }}
      />
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "16px",
        }}
      >
        <Button
          variant="contained"
          onClick={onClose}
          color="error"
          disabled={loading}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          onClick={handleConfirmClick}
          color="primary"
        >
          {loading ? "Cargando..." : "Aceptar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalDispatched;
