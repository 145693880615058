import React from 'react';
import { Box, Modal, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
const CustomModal = ({ open, onClose, title, children, disabledAutoFocus = false }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "78%", sm: "50%", md: "60%" },
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <IconButton
          sx={{ position: "absolute", top: "1vh", right: "1vw" }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          id="modal-title"
          variant="h6"
          component="div"
          style={{ marginBottom: "2vh" }}
        >
          {title}
        </Typography>

        {children}
      </Box>
    </Modal>
  );
};

export default CustomModal;
