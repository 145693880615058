import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Grid,
  Snackbar,
  TextField,
  Autocomplete,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import ingressService from "../../../services/ingressService";
import CustomMaterialTable from "../../../components/MaterialTable";
import CustomModal from "../../../components/customModal";
import * as styles from "./styles";
import ModalEgress from "./modalEgress";
import userService from "../../../services/userService";

const initialAlert = { severity: "success", message: "" };

export default function CreateEgress() {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [textEntered, setTextEntered] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [alertObj, setAlertObj] = useState(initialAlert);
  const [open, setOpen] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [amountEntered, setAmountEntered] = useState(0);
  const [cantidadIngresadaErrors, setCantidadIngresadaErrors] = useState({});
  const [inputFocus, setInputFocus] = useState(null);
  const [deposits, setDeposits] = useState([]);
  const [paging, setPaging] = useState([]);

  const columns = [
    { title: "SKU", field: "sku_id",
      width: 50
     },
    { title: "Descripción", field: "name", width: 300 },
    { title: "Stock actual", field: "stock", width: 50 },
    {
      title: "Stock a retirar",
      field: "stock_in",
      width: 50,
      render: (rowData) => (
        <TextField
          variant="outlined"
          key={rowData?.sku_id}
          size="small"
          type="text"
          style={{ width: "80px" }}
          value={rowData.amountEntered ?? ""}
          onChange={(event) => handleQuantityChange(event, rowData.sku_id)}
          inputProps={{ pattern: "[0-9]*", title: "Ingrese solo números" }}
          autoFocus={inputFocus === rowData.sku}
        />
      ),
    },
    {
      title: "Acciones",
      field: "acciones",
      width: 100,
      render: (rowData) => (
        <DeleteIcon
          variant="outlined"
          color="red"
          onClick={() => handleRemoveProduct(rowData.sku_id)}
          style={{ cursor: "pointer" }}
        />
      ),
    },
  ];

  useEffect(() => {
    fetchDepositos();
  }, []);

  useEffect(() => {
    if (textEntered.length >= 3) {
      filterProductsByText(textEntered);
    } else {
      setFilteredProducts([]);
    }
  }, [textEntered]);

  const fetchDepositos = async () => {
    const result = await userService.getDeposits();
    setDeposits(result.data);
  };

  const handleInputChange = (event, value) => {
    setTextEntered(value);
    if (value.length >= 3) {
      filterProductsByText(value);
    } else {
      setFilteredProducts(products);
    }
  };

  const handleSearch = async () => {
    setIsSearching(true);
    try {
      const res = await ingressService.getInventory();
      const data = res?.data || [];
      setProducts(data.results);
      setFilteredProducts(data.results);
      setPaging(data.paging);
    } catch (error) {
      console.error("Error al cargar productos:", error);
      setProducts([]);
      setFilteredProducts([]);
      setPaging([]);
    } finally {
      setIsSearching(false);
    }
  };

  const filterProductsByText = async (text) => {
    setIsSearching(true);
    try {
      const lowercasedText = text.toLowerCase();
      const res = await ingressService.getInventory();
      const products = res?.data?.results || [];
      const filtered = products.filter(
        (product) =>
          product.name.toLowerCase().includes(lowercasedText) ||
          product.sku_id.toLowerCase().includes(lowercasedText)
      );
      setFilteredProducts(filtered);
    } catch (error) {
      console.error("Error al filtrar productos:", error);
      setFilteredProducts([]);
    } finally {
      setIsSearching(false);
    }
  };

  const handleProductSelect = (event, value) => {
    if (value) {
      const existentProduct = selectedProducts.find(
        (selectedProduct) => selectedProduct.sku_id === value.sku_id
      );

      if (!existentProduct) {
        setSelectedProducts((prevProductos) => [
          { ...value, amountEntered: null },
          ...prevProductos,
        ]);
      }
    }
  };

  const handleQuantityChange = (event, sku) => {
    const value = event.target.value.trim();
    const errors = { ...cantidadIngresadaErrors };

    if (value === "" || (!isNaN(value) && parseInt(value, 10) >= 0)) {
      errors[sku] = "";
      setSelectedProducts((prev) =>
        prev.map((producto) =>
          producto.sku_id === sku
            ? {
                ...producto,
                amountEntered: value === "" ? null : parseInt(value, 10),
              }
            : producto
        )
      );
    } else {
      errors[sku] = "Campo requerido";
    }

    setCantidadIngresadaErrors(errors);
    setInputFocus(sku);
  };

  const handleRemoveProduct = (sku) => {
    setSelectedProducts((prev) =>
      prev.filter((product) => product.sku_id !== sku)
    );
    if (selectedProducts.length === 1) handleCloseModal();
  };

  const canConfirm = () =>
    selectedProducts.length > 0 &&
    selectedProducts.every((producto) => producto.amountEntered > 0);

  const handleClose = (_, reason) => {
    if (reason === "clickaway") return;
    setOpen(false);
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const calcularTotalUnidadesIngresadas = () => {
    const total = selectedProducts.reduce(
      (acc, producto) => acc + (producto.amountEntered || 0),
      0
    );
    setAmountEntered(total);
  };

  useEffect(() => {
    calcularTotalUnidadesIngresadas();
  }, [selectedProducts]);

  return (
    <styles.RootContainer>
      <Grid
        container
        item
        xs={10}
        justifyContent="center"
        style={{ alignContent: "baseline" }}
      >
        <Grid
          container
          item
          xs={12}
          style={{ marginTop: "2vh" }}
          justifyContent="space-between"
        >
          <Grid xs={8} style={{ textAlign: "start" }}>
            <h1 style={{ color: "#1976d2" }}>Nuevo egreso</h1>
          </Grid>
          <Grid xs={4} style={{ textAlign: "start" }}>
            <Link to="/listado-egresos">
              <Button variant="contained" color="error">
                Cancelar
              </Button>
            </Link>
          </Grid>
        </Grid>

        <Grid container item xs={12} style={{ height: "fit-content" }}>
          <Grid item xs={8}>
            <Autocomplete
              options={filteredProducts}
              getOptionLabel={(option) =>
                `SKU: ${option.sku_id}, Nombre: ${option.name}`
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Buscar productos"
                  variant="outlined"
                  onChange={(e) => setTextEntered(e.target.value)}
                />
              )}
              onChange={handleProductSelect}
              noOptionsText={isSearching ? "Buscando..." : "No hay resultados"}
            />
          </Grid>
          <Grid container item xs={4} justifyContent="end" alignItems="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenModal}
              disabled={!canConfirm()}
            >
              Crear egreso
            </Button>
          </Grid>
        </Grid>

        <Grid container item xs={12}>
          <CustomMaterialTable
            title="Productos agregados"
            data={selectedProducts}
            setData={setSelectedProducts}
            columns={columns}
            style={{ width: "100%", marginTop: "20px" }}
            mensajeDataArrayVacio="No hay productos agregados"
          />
        </Grid>
      </Grid>

      <CustomModal
        open={openModal}
        onClose={handleCloseModal}
        title="Confirmar el egreso"
      >
        <ModalEgress
          amountEntered={amountEntered}
          selectedProducts={selectedProducts}
          handleRemoveProduct={handleRemoveProduct}
          cantidadIngresadaErrors={cantidadIngresadaErrors}
          setAlertObj={(x) => setAlertObj(x)}
          setOpen={(x) => setOpen(x)}
          setSelectedProducts={(x) => setSelectedProducts(x)}
          handleCloseModal={handleCloseModal}
          deposits={deposits}
          canConfirm={canConfirm}
        />
      </CustomModal>

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={alertObj?.severity}
          sx={{ width: "100%" }}
        >
          {alertObj.message}
        </Alert>
      </Snackbar>
    </styles.RootContainer>
  );
}
