import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import LogoPdf from "../../../../src/assets/LogoPdf.png";
import { useEffect } from "react";

const ModalView = ({ ingressDetail, open, onClose }) => {
  useEffect(() => {}, [ingressDetail]);

  const generatePDF = () => {
    const doc = new jsPDF();
    const currentDate = new Date().toLocaleDateString();

    doc.setFont("Helvetica");

    // Agregar el logo en la parte superior derecha
    const logoWidth = 25; // Ancho del logo en mm
    const logoHeight = 20; // Alto del logo en mm
    doc.addImage(LogoPdf, "PNG", 170, 10, logoWidth, logoHeight);

    // Encabezado principal
    doc.setFontSize(20);
    doc.setFont("Helvetica", "bold");
    doc.text("DETALLE DE EGRESO", 105, 20, { align: "center" });

    let verticalSpace = 55;

    doc.setFontSize(12);
    doc.setFont("Helvetica", "normal");
    doc.text("Empresa Voras", 105, 30, { align: "center" });
    doc.text("Calle 123, Ciudad de Córdoba", 105, 35, { align: "center" });
    doc.text(
      "Teléfono: (123) 456-7890 | Email: contacto@empresa.com",
      105,
      40,
      { align: "center" }
    );

    doc.line(15, 45, 195, 45);

    // Información del egreso
    doc.setFontSize(10);

    doc.setFont("Helvetica", "bold");
    doc.text("Información del egreso", 20, verticalSpace);

    doc.setFont("Helvetica", "normal");
    verticalSpace += 8;

    doc.text(`Fecha de Creación: ${currentDate}`, 20, verticalSpace);
    doc.text(`Id Orden: ${ingressDetail.id}`, 110, verticalSpace);

    verticalSpace += 5;
    doc.text(`Cliente: ${ingressDetail.provider_name}`, 20, verticalSpace);
    doc.text(`Teléfono: ${ingressDetail.phone_number}`, 110, verticalSpace);

    verticalSpace += 5;
    doc.text(`Comprobante: ${ingressDetail.receipt_id}`, 20, verticalSpace);
    doc.text(`Depósito: ${ingressDetail.warehouse_id}`, 110, verticalSpace);

    verticalSpace += 5;
    doc.text(`Observaciones: ${ingressDetail.observation}`, 20, verticalSpace);
    doc.text(`Email: ${ingressDetail.email}`, 110, verticalSpace);

    verticalSpace += 10;
    doc.line(15, verticalSpace, 195, verticalSpace);

    verticalSpace += 10;
    // Tabla de productos
    verticalSpace += 10;
    const data = ingressDetail?.movement_details?.map((product) => [
      product.sku_id,
      product.description,
      product.original_count,
    ]);

    autoTable(doc, {
      startY: verticalSpace,
      head: [["SKU", "Descripción", "Cantidad"]],
      body: data,
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 115 },
        2: { cellWidth: 25 },
      },
      styles: {
        overflow: "linebreak",
        halign: "center",
        valign: "middle",
      },
    });

    verticalSpace = doc.lastAutoTable.finalY + 10;
    const totalUnidadesIngresadas = ingressDetail?.movement_details?.reduce(
      (total, producto) => total + (producto.original_count),
      0
    );
    // Pie de página
    doc.setFont("Helvetica", "bold");
    doc.text(
      `Total de productos: ${ingressDetail.movement_details.length}`,
      20,
      verticalSpace
    );
    verticalSpace += 5;
    doc.text(
      `Total de unidades: ${totalUnidadesIngresadas}`,
      20,
      verticalSpace
    );

    doc.save(`Detalle_Egreso.pdf`);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          padding: 3,
        },
      }}
    >
      <DialogTitle
        sx={{
          fontWeight: "bold",
        }}
      >
        Detalle del egreso
        <IconButton
          onClick={onClose}
          style={{ position: "absolute", right: 16, top: 16 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {/* Contenido del modal */}
      <DialogContent>
        {/* Información del ingreso */}
        <Box mb={2} p={3}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Id Orden:</strong> {ingressDetail?.id}
              </Typography>
              <Typography variant="body1">
                <strong>Proveedor:</strong> {ingressDetail?.provider_name}
              </Typography>
              <Typography variant="body1">
                <strong>Email:</strong> {ingressDetail?.email}
              </Typography>
              <Typography variant="body1">
                <strong>Depósito:</strong> {ingressDetail?.warehouse_id}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Comprobante:</strong> {ingressDetail?.receipt_id}
              </Typography>
              <Typography variant="body1">
                <strong>Fecha creación:</strong> {ingressDetail?.date_created}
              </Typography>
              <Typography variant="body1">
                <strong>Teléfono:</strong> {ingressDetail?.phone_number}
              </Typography>
              <Typography variant="body1">
                <strong>Observaciones:</strong> {ingressDetail?.observation}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        {/* Tabla de productos */}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>SKU</strong>
              </TableCell>
              <TableCell width={500}>
                <strong>Descripción</strong>
              </TableCell>
              <TableCell align="center">
                  <strong>Cantidad</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ingressDetail?.movement_details?.map((producto, index) => (
              <TableRow key={index}>
                <TableCell>{producto.sku_id}</TableCell>
                <TableCell>{producto.description}</TableCell>
                <TableCell align="center">{producto.original_count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>

      {/* Acciones del modal */}
      <DialogActions>
        <Button variant="contained" color="primary" onClick={generatePDF}>
          Descargar PDF
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalView;
