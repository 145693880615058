import { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Autocomplete from "@mui/material/Autocomplete";
import ingressService from "../../../services/ingressService";
import DeleteIcon from "@mui/icons-material/Delete";
import productsService from "../../../services/productsService";

const CrearProductoCombo = ({ open, onClose }) => {
  const [sku, setSku] = useState(""); // SKU del producto
  const [nombre, setNombre] = useState(""); // Nombre del producto
  const [productos, setProductos] = useState([]); // Lista de productos en la tabla
  const [search, setSearch] = useState(""); // Valor del input de búsqueda
  const [selectedProducto, setSelectedProducto] = useState(null); // Producto seleccionado
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [textEntered, setTextEntered] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const user_id = sessionStorage.getItem("User");

  const handleAddProducto = () => {
    if (selectedProducto) {
      // Verificar que el producto no esté ya en la tabla
      const exists = productos.find((p) => p.id === selectedProducto.id);
      if (!exists) {
        setProductos([...productos, { ...selectedProducto, cantidad: 1 }]);
        setSelectedProducto(null);
        setSearch(""); // Limpiar búsqueda
        setFilteredProducts([]);
      } else {
        alert("El producto ya está en la lista.");
      }
    }
  };

  const isCrearDisabled = !sku || !nombre || productos.length === 0;

  useEffect(() => {
    if (open) {
      setSku("");
      setNombre("");
      setProductos([]);
      setSearch("");
      setSelectedProducto(null);
      setFilteredProducts([]);
      setTextEntered("");
    }
  }, [open]);

  useEffect(() => {
    if (textEntered.length >= 3) {
      console.log(textEntered);
      filterProductsByText(textEntered);
    } else {
      setFilteredProducts([]);
    }
  }, [textEntered]);

  const filterProductsByText = async (text) => {
    setIsSearching(true);
    try {
      const lowercasedText = text.toLowerCase();
      const res = await ingressService.getInventory();
      const products = res?.data?.results || [];
      const filtered = products.filter(
        (product) =>
          product.name.toLowerCase().includes(lowercasedText) ||
          product.sku_id.toLowerCase().includes(lowercasedText)
      );
      setFilteredProducts(filtered);
    } catch (error) {
      console.error("Error al filtrar productos:", error);
      setFilteredProducts([]);
    } finally {
      setIsSearching(false);
    }
  };

  const handleRemoveProduct = (index) => {
    const updatedProductos = [...productos];
    updatedProductos.splice(index, 1);
    setProductos(updatedProductos);
  };

  const handleCrear = async () => {
    // Crear el objeto con la estructura solicitada
    const productoCombo = {
      sku_id: sku,
      ean_id: `EAN${sku}`, // Aquí puedes ajustar según cómo se maneje el EAN
      name: nombre,
      height: 1, // Asignar los valores correspondientes
      deep: 1,
      width: 1,
      weight: 1,
      observation: "observation combo test", // Puedes personalizar este campo
      user_id: Number(user_id), // Reemplaza con el valor correspondiente
      is_combo: true,
      active: true,
      combo: productos.map((producto) => ({
        product_id: producto.id,
        quantity: Number(producto.cantidad),
      })),
    };

    // Mostrar el objeto en consola (para pruebas)
    console.log("Producto combo a enviar:", productoCombo);

    // Aquí enviarías el objeto a la API
    try {
      await productsService.postProductCombo(productoCombo);
      alert("Combo creado exitosamente!");
      onClose(); // Cerrar el diálogo
    } catch (error) {
      console.error("Error al crear combo:", error);
      alert("Hubo un error al crear el combo.");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Crear Combo
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ marginBottom: 2, marginTop: 1 }}>
          <Grid item xs={6}>
            <TextField
              label="SKU *"
              fullWidth
              value={sku}
              onChange={(e) => {
                const inputValue = e.target.value.toUpperCase();
                const isValid = /^[A-Z0-9]*$/.test(inputValue);
                if (isValid && inputValue.length <= 14) {
                  setSku(inputValue);
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Nombre del combo *"
              fullWidth
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          alignItems="center"
          sx={{ marginBottom: 2 }}
        >
          <Grid item xs={8}>
            <Autocomplete
              options={filteredProducts}
              getOptionLabel={(option) =>
                `SKU: ${option.sku_id}, Nombre: ${option.name}`
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Buscar productos"
                  variant="outlined"
                  onChange={(e) => setTextEntered(e.target.value)}
                />
              )}
              noOptionsText={isSearching ? "Buscando..." : "No hay resultados"}
              onChange={(event, newValue) => setSelectedProducto(newValue)} // Actualiza el producto seleccionado
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddProducto}
              fullWidth
              disabled={!selectedProducto}
            >
              Agregar
            </Button>
          </Grid>
        </Grid>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width={200}>Nombre</TableCell>
                <TableCell width={50} align="center">
                  Cantidad
                </TableCell>
                <TableCell width={50} align="center">
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productos.map((producto, index) => (
                <TableRow key={index}>
                  <TableCell width={200}>{producto.name}</TableCell>
                  {/* Campo editable para la cantidad */}
                  <TableCell width={50}>
                    <TextField
                      type="text"
                      value={producto.cantidad}
                      onChange={(e) => {
                        const value = e.target.value;
                        // Permitir solo números
                        if (/^\d*$/.test(value)) {
                          const updatedProductos = [...productos];
                          updatedProductos[index].cantidad = value;
                          setProductos(updatedProductos);
                        }
                      }}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell align="center">
                    <DeleteIcon
                      variant="outlined"
                      color="red"
                      onClick={() => handleRemoveProduct(index)}
                      style={{ cursor: "pointer" }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error">
          Cancelar
        </Button>
        <Button onClick={handleCrear} color="primary" disabled={isCrearDisabled}>
          Crear
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CrearProductoCombo;
