import client from "../client/client";


const PostCreateEgress = async (data) => {
  const user_id = sessionStorage.getItem('User');
  const requestData = {
    ...data,
    user_id: parseInt(user_id, 10)
  };
  try {
    const resp = await client.post(`/warehouse-api/movement/save`, requestData);
    return resp;
  } catch (error) {
    console.error("Error al crear un egreso:", error);
    throw error;
  }
};

const getAllEgress = async (params) => {
  const user_id = sessionStorage.getItem('User');
  const requestData = {
    user_id: parseInt(user_id, 10),
    from: params.from,
    to: params.to,
    page: params.page,
    page_size: params.pageSize,
    type: params.type,
    status: params.status
  };
  try {
    const resp = await client.get("warehouse-api/movement/search", {
      params: requestData
    });
    return resp;
  } catch (error) {
    console.error("Error al obtener egresos:", error);
    throw error;
  }
};


const PostInPreparation = async (data) => {
  try {
    const resp = await client.post(
      "productos/comenzarPreparacionEgreso/",
      data
    );
    return resp;
  } catch (error) {
    console.error("Error al pasar en preparacion:", error);
    throw error;
  }
};
const PostPrepareEgress = async (data) => {
  try {
    const resp = await client.post("productos/prepararEgreso/", data);
    return resp;
  } catch (error) {
    console.error("Error al crear ingreso:", error);
    throw error;
  }
};
const GetPrepareEgress = async () => {
  try {
    const resp = await client.get("productos/prepararEgreso/");
    return resp;
  } catch (error) {
    console.error("Error al crear ingreso:", error);
    throw error;
  }
};
const PostEgress = async (data) => {
  try {
    const resp = await client.post("productos/egresar/", data);
    return resp;
  } catch (error) {
    console.error("Error al crear ingreso:", error);
    throw error;
  }
};

const deleteEgress = async (data) => {
  try {
    const resp = await client.delete("productos/CrearEgreso/", { data });
    return resp;
  } catch (error) {
    console.error("Error al eliminar ingreso:", error);
    throw error;
  }
};

const getFollowUp = async (data) => {
  // parametro hasheado hasta que este el back
  if (data.codigo === "1")
    return {
      desde: "18-02-2024",
      hasta: "24-02-2024",
      data: [
        { estado: "En preparacion", fecha: "2021-10-10" },
        { estado: "Preparado", fecha: "2021-10-10" },
        { estado: "Enviado" },
      ],
    };
  try {
    const resp = await client.get("productos/seguimiento/", { params: data });
    return resp;
  } catch (error) {
    console.error("Error al obtener productos:", error);
    throw error;
  }
};

let egressService = {
  PostCreateEgress,
  getAllEgress,
  PostInPreparation,
  PostPrepareEgress,
  GetPrepareEgress,
  PostEgress,
  deleteEgress,
  getFollowUp,
};

export default egressService;
