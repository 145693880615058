import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import productsService from "../../../services/productsService";

const initialAlert = {
  severity: "success",
  message: "",
};

const MultiBultoModal = ({ open, onClose }) => {
  const [cantidadBultos, setCantidadBultos] = useState();
  const [currentStep, setCurrentStep] = useState(0); // 0: Principal, 1+: Secundarios
  const [bultoPrincipal, setBultoPrincipal] = useState({});
  const [bultosData, setBultosData] = useState([]);
  const [alertObj, setAlertObj] = useState(initialAlert);
  const [openAlert, setOpenAlert] = useState(false);
  const user_id = sessionStorage.getItem("User");


  // Manejar datos del bulto principal
  const handlePrincipalChange = (field, value) => {
    setBultoPrincipal({ ...bultoPrincipal, [field]: value });
  };

  // Manejar datos de los bultos secundarios
  const handleBultoChange = (field, value) => {
    const newBultosData = [...bultosData];
    newBultosData[currentStep - 1] = {
      ...newBultosData[currentStep - 1],
      [field]: value,
    };
    setBultosData(newBultosData);
  };

  // Avanzar al siguiente paso
  const handleNext = () => {
    if (currentStep === 0) {
      setBultosData(Array(cantidadBultos).fill({}));
    }
    setCurrentStep(currentStep + 1);
  };

  // Retroceder al paso anterior
  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const handleSave = async () => {
    const bultosInternos = bultosData.map((bulto, index) => ({
      sku_id: bulto.sku,
      ean_id: bulto.ean,
      name: bulto.nombre || "",
      height: Number(bulto.alto || 0),
      deep: Number(bulto.largo || 0),
      width: Number(bulto.ancho || 0),
      weight: Number(bulto.peso || 0),
      observation: bulto.observaciones || "",
      user_id: Number(user_id),
      is_multipack: false,
      active: true,
    }));

    const bultoPrincipalObj = {
      sku_id: bultoPrincipal.sku,
      ean_id: bultoPrincipal.ean || "",
      name: bultoPrincipal.nombre || "",
      height: Number(bultoPrincipal.alto || 0),
      deep: Number(bultoPrincipal.largo || 0),
      width: Number(bultoPrincipal.ancho || 0),
      weight: Number(bultoPrincipal.peso || 0),
      observation: bultoPrincipal.observaciones || "",
      user_id: Number(user_id), // Ajustar según el contexto
      is_multipack: true,
      active: true,
    };

    const allBultos = [bultoPrincipalObj, ...bultosInternos];

    try {
      let newProduct = await productsService.postProductMultiPack(allBultos);
      if (newProduct?.data) {
        setAlertObj({
          severity: "success",
          message: "Producto guardado exitosamente",
        });
        setOpenAlert(true)
        setTimeout(() => {
          onClose();
        }, 1000);
      }
    } catch (error) {
      setAlertObj({
        severity: "error",
        message: "Ocurrió un error al guardar el producto",
      });
      setOpenAlert(true)

    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {currentStep === 0
          ? "Ingresar Información del Bulto"
          : `Bulto ${currentStep}/${cantidadBultos}`}
      </DialogTitle>
      <DialogContent>
        {currentStep === 0 && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Cantidad de Bultos *"
                  type="number"
                  fullWidth
                  margin="normal"
                  value={cantidadBultos}
                  onChange={(e) =>
                    setCantidadBultos(Math.max(1, parseInt(e.target.value, 10)))
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="SKU *"
                  margin="normal"
                  fullWidth
                  value={bultoPrincipal.sku || ""}
                  onChange={(e) => handlePrincipalChange("sku", e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="EAN *"
                  fullWidth
                  value={bultoPrincipal.ean || ""}
                  onChange={(e) => handlePrincipalChange("ean", e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Nombre *"
                  fullWidth
                  value={bultoPrincipal.nombre || ""}
                  onChange={(e) =>
                    handlePrincipalChange("nombre", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Alto (cm) *"
                  type="number"
                  fullWidth
                  value={bultoPrincipal.alto || ""}
                  onChange={(e) =>
                    handlePrincipalChange("alto", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Ancho (cm) *"
                  type="number"
                  fullWidth
                  value={bultoPrincipal.ancho || ""}
                  onChange={(e) =>
                    handlePrincipalChange("ancho", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Largo (cm) *"
                  type="number"
                  fullWidth
                  value={bultoPrincipal.largo || ""}
                  onChange={(e) =>
                    handlePrincipalChange("largo", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Peso (gramos) *"
                  type="number"
                  fullWidth
                  value={bultoPrincipal.peso || ""}
                  onChange={(e) =>
                    handlePrincipalChange("peso", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Observaciones *"
                  fullWidth
                  multiline
                  rows={2}
                  value={bultoPrincipal.observaciones || ""}
                  onChange={(e) =>
                    handlePrincipalChange("observaciones", e.target.value)
                  }
                />
              </Grid>
            </Grid>
          </>
        )}
        {currentStep > 0 && (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="SKU *"
                fullWidth
                value={bultosData[currentStep - 1]?.sku || ""}
                onChange={(e) => handleBultoChange("sku", e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="EAN *"
                fullWidth
                value={bultosData[currentStep - 1]?.ean || ""}
                onChange={(e) => handleBultoChange("ean", e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Nombre *"
                fullWidth
                value={bultosData[currentStep - 1]?.nombre || ""}
                onChange={(e) => handleBultoChange("nombre", e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Alto (cm) *"
                type="number"
                fullWidth
                value={bultosData[currentStep - 1]?.alto || ""}
                onChange={(e) => handleBultoChange("alto", e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Ancho (cm) *"
                type="number"
                fullWidth
                value={bultosData[currentStep - 1]?.ancho || ""}
                onChange={(e) => handleBultoChange("ancho", e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Largo (cm) *"
                type="number"
                fullWidth
                value={bultosData[currentStep - 1]?.largo || ""}
                onChange={(e) => handleBultoChange("largo", e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Peso (gramos) *"
                type="number"
                fullWidth
                value={bultosData[currentStep - 1]?.peso || ""}
                onChange={(e) => handleBultoChange("peso", e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Observaciones *"
                fullWidth
                multiline
                rows={2}
                value={bultosData[currentStep - 1]?.observaciones || ""}
                onChange={(e) =>
                  handleBultoChange("observaciones", e.target.value)
                }
              />
            </Grid>
          </Grid>
        )}
        <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity={alertObj?.severity}
            sx={{ width: "100%" }}
          >
            {alertObj.message}
          </Alert>
        </Snackbar>
      </DialogContent>
      <DialogActions>
        {currentStep > 0 && (
          <Button onClick={handleBack} variant="outlined">
            Anterior
          </Button>
        )}
        {currentStep !== cantidadBultos && (
          <Button onClick={handleNext} variant="contained">
            Siguiente
          </Button>
        )}
        {currentStep === cantidadBultos && (
          <Button onClick={handleSave} variant="contained">
            Guardar
          </Button>
        )}
        <Button onClick={onClose} color="error" variant="outlined">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MultiBultoModal;
