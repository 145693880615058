import client from "../client/client";

const postProduct = async (data) => {
  try {
    let resp = await client.post("product-api/save", data);
    return resp;
  } catch (error) {
    console.log(error.response.data);
    console.error("Error al crear productos:", error);
    throw error;
  }
};

const postProductMultiPack = async (data) => {
  try {
    let resp = await client.post("product-api/savemultipack", data);
    return resp;
  } catch (error) {
    console.error("Error al crear productos:", error);
    throw error;
  }
};

const postProductCombo = async (data) => {
  try {
    let resp = await client.post("product-api/savecombo", data);
    return resp;
  } catch (error) {
    console.error("Error al crear productos:", error);
    throw error;
  }
};

const putProducts = async (data) => {  
  console.log(data);
  
  try {
    let resp = await client.put(`product-api/update`, data);
    return resp;
  } catch (error) {
    console.error("Error al actualizar productos:", error);
    throw error;
  }
};


const getProductsBySku = async (productoSKU) => {
  try {
    const resp = await client.get(`product-api/product/${productoSKU}`);
    return resp;
  } catch (error) {
    console.error("Error al obtener productos por SKU:", error);
    throw error;
  }
};


const getHistory = async (sku) => {
  try {
    const resp = await client.get("productos/movimientos/?busqueda=" + sku);
    return resp;
  } catch (error) {
    console.error("Error al obtener historial:", error);
    throw error;
  }
};

let productsServices = {
  postProduct,
  putProducts,
  getProductsBySku,
  getHistory,
  postProductMultiPack,
  postProductCombo
};

export default productsServices;
