import { Button, Grid, TextField, Typography } from "@mui/material";
import CustomMaterialTable from "../../../components/MaterialTable";
import { formatDateString } from "../../../helpers";
import { useState } from "react";
import ListadoDeProductos from "../../../components/ListadoDeProductos";
import ingressService from "../../../services/ingressService";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { useEffect } from "react";
import LogoPdf from "../../../../src/assets/LogoPdf.png";

const ModalListadoIngresos = ({
  ingressDetail,
  handleCloseDetailModal,
  isEdit,
  fetchAllIngress,
  setAlertObj,
  setOpenSnack,
}) => {
  const [amountEntered, setAmountEntered] = useState({});
  const [erroresCantidad, setErroresCantidad] = useState({});
  const [inputFocus, setInputFocus] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, [ingressDetail]);

  const handleConfirm = async () => {
    setLoading(true);

    ingressDetail.movement_details.forEach((detalle) => {
      if (amountEntered[detalle.product_id] === undefined) {
        setAmountEntered((prevAmountEntered) => ({
          ...prevAmountEntered,
          [detalle.product_id]: detalle.effective_count,
        }));
      }
    });

    const todasLasCantidadesIngresadas = ingressDetail.movement_details?.every(
      (detalle) => amountEntered[detalle.product_id] !== undefined
    );

    if (!todasLasCantidadesIngresadas) {
      setLoading(false);
      setAlertObj({
        severity: "error",
        message: "Por favor, ingrese todas las cantidades",
      });
      setOpenSnack(true);
      return;
    }

    // const status = ingressDetail.movement_details.every(
    //   (detalle) => Number(amountEntered[detalle.product_id]) < Number(detalle.effective_count)
    // ) ? "partially_accepted" : "accepted";

    // Crear el objeto para enviar
    const ingressData = {
      id: ingressDetail.id,
      type: ingressDetail.type,
      status: "accepted",
      sub_movement_id: ingressDetail.sub_movement_id,
      receipt_id: ingressDetail.receipt_id,
      provider_name: ingressDetail.provider_name,
      warehouse_id: ingressDetail.warehouse_id,
      email: ingressDetail.email,
      observation: ingressDetail.observation,
      phone_number: ingressDetail.phone_number,
      user_id: ingressDetail.user_id,
      movement_details: ingressDetail.movement_details.map((detalle) => ({
        product_id: detalle.product_id,
        description: detalle.description,
        original_count: detalle.original_count,
        effective_count: parseInt(amountEntered[detalle.product_id] || 0),
      })),
    };

    try {
      const result = await ingressService.postRegisterIngress(ingressData);
      if (result?.data) {
        setAlertObj({
          severity: "success",
          message: "Confirmación exitosa",
        });
        handleDownloadPDF();
        setOpenSnack(true);
      } else {
        setAlertObj({
          severity: "error",
          message: result.data,
        });
        setOpenSnack(true);
      }
      fetchAllIngress();
      handleCloseDetailModal();
    } catch (error) {
      console.error("Error al confirmar", error);
    } finally {
      setLoading(false);
      handleCloseDetailModal();
    }
  };

  const handleQuantityChange = (event, sku) => {
    const newValue = event.target.value;

    if (!/^\d*$/.test(newValue)) {
      setErroresCantidad((prevErroresCantidad) => ({
        ...prevErroresCantidad,
        [sku]: "Solo se permiten números",
      }));
      return;
    }

    setAmountEntered((prevAmountEntered) => ({
      ...prevAmountEntered,
      [sku]: newValue,
    }));

    setErroresCantidad((prevErroresCantidad) => ({
      ...prevErroresCantidad,
      [sku]: newValue.trim() === "" ? "Ingrese la cantidad" : null,
    }));

    setInputFocus(sku);
  };

  const calculateUnitsEntered = () => {
    let totalUnitsEntered = 0;

    ingressDetail?.movement_details?.forEach((producto) => {
      totalUnitsEntered += parseInt(amountEntered[producto.product_id]);
    });

    return totalUnitsEntered;
  };

  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    const totalUnitsEntered = calculateUnitsEntered();

    doc.setFont("Helvetica");

    // Agregar el logo en la parte superior derecha
    const logoWidth = 25; // Ancho del logo en mm
    const logoHeight = 20; // Alto del logo en mm
    doc.addImage(LogoPdf, "PNG", 170, 10, logoWidth, logoHeight);

    // Encabezado principal
    doc.setFontSize(20);
    doc.setFont("Helvetica", "bold");
    doc.text("DETALLE DE INGRESO", 105, 20, { align: "center" });

    let verticalSpace = 55;

    doc.setFontSize(12);
    doc.setFont("Helvetica", "normal");
    doc.text("Empresa Voras", 105, 30, { align: "center" });
    doc.text("Calle 123, Ciudad de Córdoba", 105, 35, { align: "center" });
    doc.text(
      "Teléfono: (123) 456-7890 | Email: contacto@empresa.com",
      105,
      40,
      { align: "center" }
    );

    doc.line(15, 45, 195, 45);

    // Información del ingreso
    doc.setFontSize(10);

    doc.setFont("Helvetica", "bold");
    doc.text("Información del ingreso", 20, verticalSpace);

    doc.setFont("Helvetica", "normal");
    verticalSpace += 8;

    doc.text(
      `Fecha de Creación: ${formatDateString(ingressDetail.date_created)}`,
      20,
      verticalSpace
    );
    doc.text(`Id Orden: ${ingressDetail.id}`, 110, verticalSpace);

    verticalSpace += 5;
    doc.text(`Proveedor: ${ingressDetail.provider_name}`, 20, verticalSpace);
    doc.text(`Teléfono: ${ingressDetail.phone_number}`, 110, verticalSpace);

    verticalSpace += 5;
    doc.text(`Comprobante: ${ingressDetail.receipt_id}`, 20, verticalSpace);
    doc.text(`Depósito: ${ingressDetail.warehouse_id}`, 110, verticalSpace);

    verticalSpace += 5;
    doc.text(`Observaciones: ${ingressDetail.observation}`, 20, verticalSpace);
    doc.text(`Email: ${ingressDetail.email}`, 110, verticalSpace);

    verticalSpace += 10;
    doc.line(15, verticalSpace, 195, verticalSpace);

    // Tabla de productos
    verticalSpace += 10;
    const data = ingressDetail.movement_details.map((product) => [
      product.sku_id,
      product.description,
      product.original_count,
      amountEntered[product.product_id] || "0",
    ]);

    autoTable(doc, {
      startY: verticalSpace,
      head: [
        ["SKU", "Descripción", "Cantidad declarada", "Cantidad ingresada"],
      ],
      body: data,
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 90 },
        2: { cellWidth: 25 },
        3: { cellWidth: 25 },
      },
      styles: {
        overflow: "linebreak",
        halign: "center",
        valign: "middle",
      },
    });

    verticalSpace = doc.lastAutoTable.finalY + 10;

    // Pie de página
    doc.setFont("Helvetica", "bold");
    // Calcular los valores basados en ingressDetail.movement_details
    const totalSKU = ingressDetail.movement_details.length;

    const completedSKU = ingressDetail.movement_details.filter(
      (detail) =>
        (amountEntered[detail.product_id] || detail.effective_count || 0) > 0
    ).length;

    const totalUnidadesIngresadas1 = ingressDetail.movement_details.reduce(
      (total, detail) =>
        total +
        Number(amountEntered[detail.product_id] || detail.effective_count || 0),
      0
    );    

    const totalUnidadesOriginales = ingressDetail.movement_details.reduce(
      (total, detail) => total + (detail.original_count || 0),
      0
    );

    // Escribir los valores en el documento
    doc.text(
      `Total de productos: ${totalSKU} / ${completedSKU}`,
      20,
      verticalSpace
    );
    verticalSpace += 5;
    doc.text(
      `Total de unidades: ${totalUnidadesOriginales} / ${totalUnidadesIngresadas1}`,
      20,
      verticalSpace
    );

    doc.save(`Detalle_ingreso_${ingressDetail.id}.pdf`);
  };

  const columnsProductos = [
    {
      title: "SKU",
      field: "sku_id",
      width: 50,
      sorting: false,
    },
    {
      title: "Descripcion",
      field: "description",
      sorting: false,
      width: 220,
    },
    {
      title: (
        <div style={{ textAlign: "center" }}>
          Cantidad <br /> declarada
        </div>
      ),
      field: "original_count",
      sorting: false,
      width: 40,
    },
    {
      title: (
        <div style={{ textAlign: "center" }}>
          Cantidad
          <br /> ingresada
        </div>
      ),
      sorting: false,
      field: "effective_count",
      width: 40,
      render: (rowData) => (
        <TextField
          variant="outlined"
          key={rowData?.product_id}
          size="small"
          type="text"
          style={{
            width: "80px",
          }}
          value={amountEntered[rowData?.product_id] || ""}
          onChange={(event) => handleQuantityChange(event, rowData?.product_id)}
          inputProps={{
            pattern: "[0-9]*",
            title: "Ingrese solo números",
          }}
          autoFocus={inputFocus === rowData?.product_id}
          error={erroresCantidad[rowData?.product_id] !== null}
          helperText={erroresCantidad[rowData?.product_id]}
        />
      ),
    },
  ];

  return (
    <>
      {!isEdit ? (
        <>
          <Grid container>
            <Grid item xs={4}>
              <Typography>
                Id Orden: <b>{ingressDetail?.id}</b>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                Comprobante: <b>{ingressDetail?.receipt_id}</b>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                Fecha creación:{" "}
                <b>{formatDateString(ingressDetail?.date_created)}</b>
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography>
                Proveedor: <b>{ingressDetail?.provider_name}</b>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                Deposito: <b>{ingressDetail?.warehouse_id}</b>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                Telefono: <b>{ingressDetail?.phone_number}</b>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                Email: <b>{ingressDetail?.email}</b>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                Observaciones: <b>{ingressDetail?.observation}</b>
              </Typography>
            </Grid>
          </Grid>

          <ListadoDeProductos
            productos={ingressDetail.movement_details}
            withDeclared={true}
          />
        </>
      ) : (
        <>
          <CustomMaterialTable
            title="Listado de Productos"
            data={ingressDetail?.movement_details.map((item) => ({
              ...item,
              id: item.product_id,
            }))}
            columns={columnsProductos}
            style={{
              width: "100%",
              margin: "5px 0px",
              maxHeight: "300px",
              overflowY: "auto",
            }}
            mensajeDataArrayVacio="No hay ingresos disponibles"
          />
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "right",
              marginTop: "20px",
            }}
          >
            <Button
              variant="contained"
              color="error"
              onClick={handleCloseDetailModal}
              style={{ marginRight: "16px" }}
            >
              Cancelar
            </Button>
            <Button variant="contained" color="primary" onClick={handleConfirm}>
              {loading ? "Cargando..." : "Confirmar"}
            </Button>
          </Grid>
        </>
      )}
    </>
  );
};
export default ModalListadoIngresos;
