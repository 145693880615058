import React from 'react';
import MaterialTable from "@material-table/core";
//import Pagination from '@material-ui/lab/Pagination';
import { tableIcons } from "../../helpers/materialTableIcons";

const CustomMaterialTable = ({
  title,
  data,
  columns,
  style,
  showOptions = true,
  mensajeDataArrayVacio,
  options,
}) => {
  return (
    <>
      <MaterialTable
        style={style}
        title={title}
        columns={columns}
        data={data}
        icons={tableIcons}
        options={{
          ...options,
          draggable: false, // Deshabilita mover columnas
          resizable: false, // Deshabilita redimensionar columnas
          sorting: false,
          headerStyle: {
            textAlign: 'center', // Centrar texto en encabezados
          },
          cellStyle: {
            textAlign: 'center', // Centrar texto en celdas
          },
          rowStyle: {
            overflowWrap: "break-word",
          },
          actionsColumnIndex: -1,
          //paging: false,
          search: false,
          pageSize: 5, // Cantidad de filas por página
          pageSizeOptions: [5, 10, 20], // Opciones para la cantidad de filas por página
          paginationType: "normal", // Tipo de paginación: 'normal' o 'stepped'
        }}
        localization={{
          body: {
            emptyDataSourceMessage: 'No hay datos para mostrar',
            addTooltip: 'Añadir',
            deleteTooltip: 'Eliminar',
            editTooltip: 'Editar',
            filterRow: {
              filterTooltip: 'Filtrar'
            },
            editRow: {
              deleteText: '¿Estás seguro de eliminar este elemento?',
              cancelTooltip: 'Cancelar',
              saveTooltip: 'Guardar'
            }
          },
          header: {
            actions: 'Acciones'
          },
          pagination: {
            labelDisplayedRows: '{from}-{to} de {count}',
            labelRows: "filas",
            labelRowsPerPage: 'Filas por página:',
            firstAriaLabel: 'Primera página',
            firstTooltip: 'Primera página',
            previousAriaLabel: 'Página anterior',
            previousTooltip: 'Página anterior',
            nextAriaLabel: 'Página siguiente',
            nextTooltip: 'Página siguiente',
            lastAriaLabel: 'Última página',
            lastTooltip: 'Última página'
          }
        }}
      />
    </>
  );
};

export default CustomMaterialTable;
