import {
  Grid,
  TextField,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useEffect, useState } from "react";
import ingressService from "../../../services/ingressService";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as styles from "./styles";
import ListadoDeProductos from "../../../components/ListadoDeProductos";
import { useNavigate } from "react-router-dom";
import LogoPdf from "../../../../src/assets/LogoPdf.png";

const initialState = {
  type: "in",
  status: "pending",
  sub_movement_id: 0,
  receipt_id: "",
  provider_name: "",
  warehouse_id: 0,
  phone_number: "",
  email: "test@test.com",
  observation: "",
};

const ModalIngreso = ({
  amountEntered,
  selectedProducts,
  handleRemoveProduct,
  cantidadIngresadaErrors,
  canConfirm,
  setAlertObj,
  setOpen,
  handleCloseModal,
  setSelectedProducts,
  deposits,
}) => {
  const [formData, setFormData] = useState(initialState);
  const [formErrors, setFormErrors] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: name === "receipt_id" ? value.toUpperCase() : value,
    }));

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  useEffect(() => {
    if (deposits.length === 1) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        warehouse_id: deposits[0].id,
      }));
    }
  }, [deposits]);

  /*  const handleEmailBlur = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(formData.email)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        email: "Ingresa un correo electrónico válido",
      }));
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        email: "",
      }));
    }
  }; */

  const handleConfirm = async () => {
    const requiredFields = ["comprobante", "proveedor", "deposito", "telefono"];
    const newFormErrors = {};
    let hasErrors = false;

    // requiredFields.forEach((field) => {
    //   console.log("formData[field]", formData[field]);
    //   if (!formData[field].trim()) {
    //     newFormErrors[field] = "Campo requerido";
    //     hasErrors = true;
    //   }
    // });

    if (hasErrors) {
      console.log("hasErrors", hasErrors);
      console.log("newFormErrors", newFormErrors);
      setFormErrors(newFormErrors);
      return;
    }

    const hasCantidadIngresadaErrors = Object.values(
      cantidadIngresadaErrors
    ).some((error) => error !== "");

    if (hasCantidadIngresadaErrors) {
      console.log("hasCantidadIngresadaErrors", hasCantidadIngresadaErrors);
      setFormErrors({ cantidadIngresada: "Campos requeridos" });
      return;
    }

    setLoading(true);

    if (canConfirm()) {
      const requestBody = {
        ...formData,
        movement_details: selectedProducts.map((product) => ({
          product_id: product.id,
          description: product.observation,
          original_count: product.amountEntered,
          effective_count: 0,
        })),
      };

      try {
        let nuevoIngreso = await ingressService.postCreateIngress(requestBody);
        if (nuevoIngreso.status) {
          setAlertObj({
            severity: "success",
            message: "Ingreso creado exitosamente",
          });
          setOpen(true);
        } else if (nuevoIngreso?.data?.Error) {
          setAlertObj({
            severity: "error",
            message: "Ocurrió un error en el ingreso",
          });
          setOpen(true);
        }
        setTimeout(() => {
          handleCloseModal();
          navigate("/listado-ingresos");
        }, 2000);
      } catch {
        setAlertObj({
          severity: "error",
          message: "Ocurrió un error en el ingreso",
        });
        setOpen(true);
      } finally {
        setLoading(false);
      }
    }
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    const currentDate = new Date().toLocaleDateString();

    doc.setFont("Helvetica");

    // Agregar el logo en la parte superior derecha
    const logoWidth = 25; // Ancho del logo en mm
    const logoHeight = 20; // Alto del logo en mm
    doc.addImage(LogoPdf, "PNG", 170, 10, logoWidth, logoHeight);

    // Encabezado principal
    doc.setFontSize(20);
    doc.setFont("Helvetica", "bold");
    doc.text("DETALLE DE INGRESO", 105, 20, { align: "center" });

    let verticalSpace = 55;

    doc.setFontSize(12);
    doc.setFont("Helvetica", "normal");
    doc.text("Empresa Voras", 105, 30, { align: "center" });
    doc.text("Calle 123, Ciudad de Córdoba", 105, 35, { align: "center" });
    doc.text(
      "Teléfono: (123) 456-7890 | Email: contacto@empresa.com",
      105,
      40,
      { align: "center" }
    );

    doc.line(15, 45, 195, 45);

    // Información del ingreso
    doc.setFontSize(10);

    doc.setFont("Helvetica", "bold");
    doc.text("Información del ingreso", 20, verticalSpace);

    doc.setFont("Helvetica", "normal");
    verticalSpace += 8;

    doc.text(`Fecha de Creación: ${currentDate}`, 20, verticalSpace);
    doc.text(`Comprobante: ${formData.receipt_id}`, 110, verticalSpace);

    verticalSpace += 5;

    doc.text(`Proveedor: ${formData.provider_name}`, 20, verticalSpace);
    doc.text(`Depósito: ${formData.warehouse_id}`, 110, verticalSpace);

    verticalSpace += 5;

    doc.text(`Teléfono: ${formData.phone_number}`, 20, verticalSpace);
    doc.text(`Email: ${formData.email}`, 110, verticalSpace);

    verticalSpace += 5;

    doc.text(`Observaciones: ${formData.observation}`, 20, verticalSpace);

    verticalSpace += 10;
    doc.line(15, verticalSpace, 195, verticalSpace);

    // Tabla de productos
    verticalSpace += 10;
    const data = selectedProducts.map((product) => [
      product.sku_id,
      product.name,
      product.amountEntered.toString(),
    ]);

    autoTable(doc, {
      startY: verticalSpace,
      head: [["SKU", "Descripción", "Cantidad declarada"]],
      body: data,
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 115 },
        2: { cellWidth: 25 },
      },
      styles: {
        overflow: "linebreak",
        halign: "center",
        valign: "middle",
      },
    });

    verticalSpace = doc.lastAutoTable.finalY + 10;

    doc.save(`Detalle_Ingreso.pdf`);
    setSelectedProducts([]);
    setFormData(initialState);
    handleCloseModal();
  };

  return (
    <>
      <Grid container 
      spacing={1}
      >
        <Grid item xs={6}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              style={{ color: "red", fontSize: "1rem"}}
            >
              *
            </span>

            <TextField
              fullWidth
              size="small"
              label="Comprobante asociado"
              name="receipt_id"
              value={formData.receipt_id}
              onChange={handleChange}
              error={!!formErrors.receipt_id}
              helperText={formErrors.receipt_id}
            />
          </div>
        </Grid>
        <Grid item xs={6}>
        <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              style={{ color: "red", fontSize: "1rem"}}
            >
              *
            </span>
          <TextField
            fullWidth
            size="small"
            label="Proveedor"
            name="provider_name"
            value={formData.provider_name}
            onChange={handleChange}
            error={!!formErrors.provider_name}
            helperText={formErrors.provider_name}
          />
                  </div>

        </Grid>
        <Grid item xs={6}>
        <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              style={{ color: "red", fontSize: "1rem"}}
            >
              *
            </span>
          <FormControl fullWidth>
            {deposits.length === 1 ? (
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "8px",
                  fontSize: "16px",
                }}
              >
                {deposits[0].name.toUpperCase()}
              </span>
            ) : (
              <>
                <InputLabel id="select-label">
                  {!!formErrors.warehouse_id
                    ? formErrors.warehouse_id
                    : "Almacén"}
                </InputLabel>
                <Select
                  labelId="select-label"
                  id="select"
                  value={formData.warehouse_id}
                  label="Almacén"
                  name="warehouse_id"
                  onChange={handleChange}
                  error={!!formErrors.warehouse_id}
                  helperText={formErrors.warehouse_id}
                >
                  <MenuItem value="0">Seleccione un almacén</MenuItem>
                  {deposits.map((element) => (
                    <MenuItem key={element.id} value={element.id}>
                      {element.name}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}
          </FormControl>
          </div>

        </Grid>

        <Grid item xs={6}>
        <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              style={{ color: "red", fontSize: "1rem"}}
            >
              *
            </span>
          <TextField
            fullWidth
            size="small"
            label="Telefono"
            name="phone_number"
            type="text"
            value={formData.phone_number}
            onChange={handleChange}
            error={!!formErrors.phone_number}
            helperText={formErrors.phone_number}
            inputProps={{
              pattern: "[0-9]*", // Solo permite números
              title: "Ingrese solo números",
            }}
          />        </div>

        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            size="small"
            label="Observaciones"
            name="observation"
            value={formData.observation}
            onChange={handleChange}
            error={!!formErrors.observation}
            helperText={formErrors.observation}
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "10px",
          }}
        >
          <Typography>
            Total de unidades a ingresar: <b>{amountEntered}</b>
          </Typography>
          <br></br>
          <Typography>
            Total SKUs: <b>{selectedProducts.length}</b>
          </Typography>
        </Grid>
        <ListadoDeProductos
          productos={selectedProducts}
          handleRemoveProduct={handleRemoveProduct}
        />
      </Grid>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "right",
          marginTop: "20px",
        }}
      >
        <Button
          variant="contained"
          color="error"
          onClick={handleCloseModal}
          style={{ marginRight: "16px" }}
        >
          Cancelar
        </Button>
        <Button variant="contained" color="primary" onClick={handleConfirm}>
          {loading ? "Cargando..." : "Confirmar ingreso"}
        </Button>
      </Grid>
    </>
  );
};

export default ModalIngreso;
