import React, { useState, useEffect } from "react";
import TablaInventario from "./tablaInventario";
import CustomModal from "../../components/customModal";
import CrearProducto from "./productos/crearproducto";
import HistorialModal from "./historial/HistorialModal";
import { Grid, TextField, Button, Menu, MenuItem } from "@mui/material";
import MultiBultoModal from "./productos/productMultiPack";
import CrearProductoCombo from "./productos/combo";

const Inventario = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalHistorialOpen, setModalHistorialOpen] = useState(false);
  const [productoCreado, setProductoCreado] = useState(0);
  const [productoSKU, setProductoSKU] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [openModalMultiPack, setOpenModalMultiPack] = useState(false);
  const [openModalCombo, setOpenModalCombo] = useState(false);

  // Este useEffect se ejecutará cada vez que el valor de productoSKU cambie
  useEffect(() => {
    if (productoSKU) {
      console.log("Producto SKU actual:", productoSKU);
    }
  }, [productoSKU]);

  const handleFilterChange = (e) => {
    const value = e.target.value.toUpperCase();
    if (value.length >= 3 || value.length === 0) {
      setFilterText(value);
    }
  };

  // Abre el modal para editar un producto existente, pasando su SKU
  function handleOpenModalEdit(sku) {
    setProductoSKU(sku);
    setModalOpen(true);
  }

  // Abre el modal para ver el historial de un producto, pasando su SKU
  function handleOpenModalHistorial(sku) {
    setProductoSKU(sku);
    setModalHistorialOpen(true);
  }

  function hadnleCloseModalEdit() {
    setProductoSKU("");
    setModalOpen(false);
  }

  const handleCreateProducto = () => {
    setProductoCreado(productoCreado + 1);
  };

  //despliega el modal
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (option) => {
    if (option === "Producto convencional") {
      handleOpenModal();
    } else if (option === "Combo") {
      console.log("entro");
      handleOpenModalCombo();
    } else if (option === "Multibulto") {
      handleOpenModalMultiPack();
    }
    console.log(`Seleccionaste: ${option}`);
    handleMenuClose();
  };

  // Modal para crear un nuevo producto
  const handleOpenModal = () => {
    setProductoSKU("");
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  // Modal para bulto
  const handleOpenModalMultiPack = () => {
    setOpenModalMultiPack(true);
  };

  const handleCloseModalMultiPack = () => {
    setModalOpen(false);
  };

  //modal para combo
  const handleOpenModalCombo = () => {
    setOpenModalCombo(true);
    console.log("true");
  };

  const handleCloseModalCombo = () => {
    setOpenModalCombo(false);
  };

  return (
    <>
      <Grid container justifyContent={"center"}>
        <Grid
          container
          item
          style={{ justifyContent: "space-between", display: "flex" }}
          xs={12}
          sm={11}
          sx={{ marginTop: "5vh" }}
        >
          <TextField
            size="small"
            id="outlined-basic"
            label="Buscar por SKU, EAN y NOMBRE"
            variant="outlined"
            style={{ width: "30%" }}
            onChange={handleFilterChange}
          />
          <div>
            <Button
              variant="contained"
              onClick={handleMenuOpen}
              sx={{ width: "200px" }}
            >
              Nuevo producto
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={isMenuOpen}
              onClose={handleMenuClose}
              slotProps={{
                paper: {
                  sx: {
                    width: anchorEl ? anchorEl.offsetWidth : undefined, // Ajusta el ancho al del botón
                  },
                },
              }}
            >
              <MenuItem
                onClick={() => handleOptionClick("Producto convencional")}
              >
                Producto convencional
              </MenuItem>
              <MenuItem onClick={() => handleOptionClick("Combo")}>
                Combo
              </MenuItem>
              <MenuItem onClick={() => handleOptionClick("Multibulto")}>
                Multibulto
              </MenuItem>
            </Menu>
          </div>
        </Grid>
        <Grid item xs={12} sm={11}>
          <TablaInventario
            filterText={filterText}
            productoCreado={productoCreado}
            handleOpenModalEdit={handleOpenModalEdit}
            handleOpenModalHistorial={handleOpenModalHistorial}
          />
        </Grid>
      </Grid>
      <HistorialModal
        open={modalHistorialOpen}
        setOpen={setModalHistorialOpen}
        productoSKU={productoSKU}
      />

      <CustomModal
        open={modalOpen}
        onClose={productoSKU ? hadnleCloseModalEdit : handleCloseModal}
        title={productoSKU ? "Editar Producto" : "Crear Producto"}
        sx={{
          width: { xs: "90%", sm: "50%", md: "50%" },
          mx: "auto",
          mt: "5vh",
        }}
      >
        <CrearProducto
          handleCreateProducto={handleCreateProducto}
          productoSKU={productoSKU}
          handleCloseModal={handleCloseModal}
        />
      </CustomModal>
      <MultiBultoModal
        open={openModalMultiPack}
        onClose={() => setOpenModalMultiPack(false)}
      />
      <CrearProductoCombo
        open={openModalCombo}
        onClose={() => setOpenModalCombo(false)}
      />
    </>
  );
};

export default Inventario;
