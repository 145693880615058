import React from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import {
  Home as HomeIcon,
  LocalShipping,
  ArrowCircleRight,
  ExpandLess,
  ExpandMore,
  AddCircleOutline as AddCircleOutlineIcon,
  AddBusiness,
  Summarize,
  ExitToApp,
  Person,
} from "@mui/icons-material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

import { Link } from "react-router-dom";
import { verifyRol } from "../helpers";

const Sidebar = ({ open, onClose }) => {
  const [openSubmenuEgresos, setOpenSubmenuEgresos] = React.useState(false);
  const [openSubmenuIngreso, setOpenSubmenuIngreso] = React.useState(false);
  const [openSubmenuIntegraciones, setOpenSubmenuIntegraciones] =
    React.useState(false);
  const [openSubmenuInventario, setOpenSubmenuInventario] =
    React.useState(false);

  const handleSubmenuClickInventario = () => {
    setOpenSubmenuInventario(!openSubmenuInventario);
  };

  const handleSubmenuClickEgresos = () => {
    setOpenSubmenuEgresos(!openSubmenuEgresos);
  };
  const handleSubmenuClickIngreso = () => {
    setOpenSubmenuIngreso(!openSubmenuIngreso);
  };

  const handleSubmenuClickIntegraciones = () => {
    setOpenSubmenuIntegraciones(!openSubmenuIntegraciones);
  };
  const handleLinkClick = () => {
    setTimeout(() => {
      onClose();
    }, 150);
  };

  const linkStyle = {
    textDecoration: "none",
    color: "black",
  };

  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <List sx={{ width: "280px" }}>
        <Link to="/home" style={linkStyle} onClick={handleLinkClick}>
          <ListItem>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Inicio" />
          </ListItem>
        </Link>
        <ListItem
          onClick={handleSubmenuClickIngreso}
          style={{ cursor: "pointer" }}
        >
          <ListItemIcon>
            <AddBusiness />
          </ListItemIcon>
          <ListItemText primary="Ordenes de Ingreso" />
          {openSubmenuIngreso ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={openSubmenuIngreso} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Link
              to="/crear-ingreso"
              style={linkStyle}
              onClick={handleLinkClick}
            >
              <ListItem style={{ paddingLeft: 25 }}>
                <ListItemIcon>
                  <AddCircleOutlineIcon />
                </ListItemIcon>
                <ListItemText primary="Nuevo Ingreso" />
              </ListItem>
            </Link>
            <Link
              to="/listado-ingresos"
              style={linkStyle}
              onClick={handleLinkClick}
            >
              <ListItem style={{ paddingLeft: 25 }}>
                <ListItemIcon>
                  <FormatListBulletedIcon />
                </ListItemIcon>
                <ListItemText primary="Todos los Ingresos" />
              </ListItem>
            </Link>
          </List>
        </Collapse>

        <Link to="/inventario" style={linkStyle} onClick={handleLinkClick}>
          <ListItem>
            <ListItemIcon>
              <Summarize />
            </ListItemIcon>
            <ListItemText primary="Inventario" />
          </ListItem>
        </Link>
        <ListItem
          style={{ cursor: "pointer" }}
          onClick={handleSubmenuClickEgresos}
        >
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary="Ordenes de Egreso" />
          {openSubmenuEgresos ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={openSubmenuEgresos} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Link
              to="/crear-egreso"
              style={linkStyle}
              onClick={handleLinkClick}
            >
              <ListItem style={{ paddingLeft: 25 }}>
                <ListItemIcon>
                  <AddCircleOutlineIcon />
                </ListItemIcon>
                <ListItemText primary="Nuevo Egreso" />
              </ListItem>
            </Link>
            <Link
              to="/listado-egresos"
              style={linkStyle}
              onClick={handleLinkClick}
            >
              <ListItem style={{ paddingLeft: 25 }}>
                <ListItemIcon>
                  <FormatListBulletedIcon />
                </ListItemIcon>
                <ListItemText primary="Todos los Egresos" />
              </ListItem>
            </Link>
          </List>
        </Collapse>

        {verifyRol(["Super Usuario"]) && (
          <Link to="/usuarios" style={linkStyle} onClick={handleLinkClick}>
            <ListItem>
              <ListItemIcon>
                <Person />
              </ListItemIcon>
              <ListItemText primary="usuarios" />
            </ListItem>
          </Link>
        )}

        {verifyRol(["Super Usuario", "Administrativo"]) && (
          <div>
            <ListItem
              style={{ cursor: "pointer" }}
              onClick={handleSubmenuClickIntegraciones}
            >
              <ListItemIcon>
                <ArrowCircleRight />
              </ListItemIcon>
              <ListItemText primary="Integraciones" />
              {openSubmenuIntegraciones ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse
              in={openSubmenuIntegraciones}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <Link to="/envios" style={linkStyle} onClick={handleLinkClick}>
                  <ListItem style={{ paddingLeft: 25 }}>
                    <ListItemIcon>
                      <LocalShipping />
                    </ListItemIcon>
                    <ListItemText primary="Envios" />
                  </ListItem>
                </Link>
              </List>
            </Collapse>
          </div>
        )}

        {verifyRol(["Super Usuario"]) && (
          <Link to="/almacen" style={linkStyle} onClick={handleLinkClick}>
            <ListItem>
              <ListItemIcon>
                <Person />
              </ListItemIcon>
              <ListItemText primary="Almacen" />
            </ListItem>
          </Link>
        )}
      </List>
    </Drawer>
  );
};

export default Sidebar;
