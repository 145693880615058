import {
  Grid,
  Tooltip,
  Button,
  Snackbar,
  Alert,
  Backdrop,
} from "@mui/material";
import CustomMaterialTable from "../../../components/MaterialTable";
import { useEffect, useState } from "react";
import ingressService from "../../../services/ingressService";
import VisibilityIcon from "@mui/icons-material/Visibility";
import * as styles from "./styles";
import { formatDateString } from "../../../helpers";
import CustomModal from "../../../components/customModal";
import { Link } from "react-router-dom";
import ModalListadoIngresos from "./modalListadoIngresos";
import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from "@mui/icons-material/Download";
import autoTable from "jspdf-autotable";
import CircularProgress from "@mui/material/CircularProgress";
import { returnRol } from "../../../utils/constants";
import { jsPDF } from "jspdf";
import FilterModal from "../../../components/MFilters/index.js";
import { Autocomplete, TextField } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ClearIcon from "@mui/icons-material/Clear";
import LogoPdf from "../../../../src/assets/LogoPdf.png";

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

const initialAlert = {
  severity: "success",
  message: "",
};

const ListadoIngresos = () => {
  const [incomeList, setIncomeList] = useState([]);
  const [openDetailModal, setOpenDetailMoodal] = useState(false);
  const [ingressDetail, setIngressDetail] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [alertObj, setAlertObj] = useState(initialAlert);
  const [buscandoIngresos, setBuscandoIngresos] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const currentDate = new Date();
  const oneMonthBefore = new Date();

  oneMonthBefore.setMonth(currentDate.getMonth() - 1);

  const [filters, setFilters] = useState({
    from: formatDate(oneMonthBefore),
    to: formatDate(currentDate),
    type: "in",
  });

  const statusOptions = {
    pending: "Pendiente",
    accepted: "Aceptado",
    cancelled: "Cancelado",
  };

  const [paginacion, setPaginacion] = useState({
    page: 1,
    page_size: 5,
    total: 0,
  });

  const fetchAllIngress = async (page, page_size) => {
    setIncomeList([]);
    setBuscandoIngresos(true);
    setOpenBackdrop(true);
    try {
      const result = await ingressService.getAllIngress({
        ...filters,
        page,
        page_size,
      });
  
      const { paging, results } = result.data;
  
      const sortedResults = results.sort(
        (a, b) => new Date(b.date_created) - new Date(a.date_created)
      );
  
      setIncomeList(sortedResults);
  
      setPaginacion((prev) => ({
        ...prev,
        page: paging.page,
        page_size: paging.page_size,
        total: Math.ceil(paging.total / paging.page_size),
      }));
    } catch (error) {
      setIncomeList([]);
    } finally {
      setBuscandoIngresos(false);
      setOpenBackdrop(false);
    }
  };
  

  useEffect(() => {
    fetchAllIngress(paginacion.page, paginacion.page_size);
  }, [filters]);

  const handleOpenFilterModal = (event) => setAnchorEl(event.currentTarget);
  const handleCloseFilterModal = () => setAnchorEl(null);
  const handleFilterApply = (newFilters) => setFilters(newFilters);

  const columns = [
    {
      title: "Fecha de Creacion",
      field: "date_created",
      render: (rowData) => formatDateString(rowData["date_created"]),
    },
    {
      title: "Id Orden",
      field: "id",
    },
    {
      title: "Proveedor",
      field: "provider_name",
    },
    {
      title: "Comprobante",
      field: "receipt_id",
      maxWidth: 100,
    },
    {
      title: "Estado",
      field: "status",
      maxWidth: 110,
      render: (rowData) => statusOptions[rowData.status],
    },
    {
      title: "Articulos Recibidos",
      field: "movement_details",
      render: (rowData) => (
        <div>
          <div>
            SKU: {rowData.movement_details.length}/
            {rowData.movement_details.some((detail) => detail.effective_count)
              ? rowData.movement_details.length
              : 0}
          </div>
          <div>
            Unidades:{" "}
            {rowData.movement_details.reduce(
              (total, detail) => total + detail.original_count,
              0
            )}
            /
            {rowData.movement_details.reduce(
              (total, detail) => total + detail.effective_count,
              0
            )}
          </div>
        </div>
      ),
    },
    {
      title: "Acciones",
      field: "acciones",
      // width: 30,
      render: (rowData) => (
        <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
          {rowData.status === "pending" && (
            <Tooltip title="Confirmar ingreso">
              <EditIcon
                variant="outlined"
                onClick={() => handleOpenDetailModal(rowData.id, true)}
                style={{ cursor: "pointer", color: "green" }}
              />
            </Tooltip>
          )}
          <Tooltip title="Detalle de ingreso">
            <VisibilityIcon
              variant="outlined"
              onClick={() => handleOpenDetailModal(rowData.id, false)}
              style={{ cursor: "pointer", color: "blue" }}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const calcularTotalUnidadesIngresadas = () => {
    let totalUnidadesIngresadas = 0;

    ingressDetail?.movement_details?.forEach((producto) => {
      totalUnidadesIngresadas += producto.effective_count || 0;
    });

    return totalUnidadesIngresadas;
  };
  const isOperario = returnRol() === "Operario";
  const sortedData = (data) => {
    if (isOperario) {
      const customOrder = {
        "Pendiente de Recibir": 1,
        "Recibido Parcialmente": 2,
        Recibido: 3,
        Cancelado: 4,
      };
      return data.sort((a, b) => {
        const orderA = customOrder[a.Estado];
        const orderB = customOrder[b.Estado];

        // Si el estado no está en el orden personalizado, colócalo al final
        const defaultOrder = Object.keys(customOrder).length + 1;

        return (orderA || defaultOrder) - (orderB || defaultOrder);
      });
    } else {
      return data.sort((a, b) => {
        const dateA = new Date(a.FechaCreacion).getTime();
        const dateB = new Date(b.FechaCreacion).getTime();
        return dateB - dateA;
      });
    }
  };

  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    const totalUnidadesIngresadas = calcularTotalUnidadesIngresadas();

    doc.setFont("Helvetica");

    // Agregar el logo en la parte superior derecha
    const logoWidth = 25; // Ancho del logo en mm
    const logoHeight = 20; // Alto del logo en mm
    doc.addImage(LogoPdf, "PNG", 170, 10, logoWidth, logoHeight);

    // Encabezado principal
    doc.setFontSize(20);
    doc.setFont("Helvetica", "bold");
    doc.text("DETALLE DE INGRESO", 105, 20, { align: "center" });

    let verticalSpace = 55;

    doc.setFontSize(12);
    doc.setFont("Helvetica", "normal");
    doc.text("Empresa Voras", 105, 30, { align: "center" }, verticalSpace);
    doc.text("Calle 123, Ciudad de Córdoba", 105, 35, { align: "center" });
    doc.text(
      "Teléfono: (123) 456-7890 | Email: contacto@empresa.com",
      105,
      40,
      { align: "center" }
    );

    doc.line(15, 45, 195, 45);

    // Información del proveedor
    doc.setFontSize(10);

    doc.setFont("Helvetica", "bold");
    doc.text("Información del ingreso", 20, verticalSpace);

    doc.setFont("Helvetica", "normal");
    verticalSpace += 8;

    doc.text(`Fecha de Creación: ${formatDateString(ingressDetail.date_created)}`, 20, verticalSpace);
    doc.text(`Id Orden: ${ingressDetail.id}`, 110, verticalSpace);
    
    verticalSpace += 5;
    doc.text(`Proveedor: ${ingressDetail.provider_name}`, 20, verticalSpace);
    doc.text(`Teléfono: ${ingressDetail.phone_number}`, 110, verticalSpace);
    
    
    verticalSpace += 5;
    doc.text(`Comprobante: ${ingressDetail.receipt_id}`, 20, verticalSpace);
    doc.text(`Depósito: ${ingressDetail.warehouse_id}`, 110, verticalSpace);
    
    
    verticalSpace += 5;
    doc.text(`Observaciones: ${ingressDetail.observation}`, 20, verticalSpace);
    doc.text(`Email: ${ingressDetail.email}`, 110, verticalSpace);


    verticalSpace += 10;
    doc.line(15, verticalSpace, 195, verticalSpace);

    // Tabla de productos
    verticalSpace += 10;
    const data = ingressDetail.movement_details.map((producto) => [
      producto.sku_id,
      producto.description,
      producto.original_count.toString(),
      producto.effective_count === 0 ? "" : producto.effective_count?.toString()
        ]);

    autoTable(doc, {
      startY: verticalSpace,
      head: [
        ["SKU", "Descripción", "Cantidad declarada", "Cantidad ingresada"],
      ],
      body: data,
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 90 },
        2: { cellWidth: 25 },
        3: { cellWidth: 25 },
      },
      styles: {
        overflow: "linebreak",
        halign: "center",
        valign: "middle",
      },
    });

    verticalSpace = doc.lastAutoTable.finalY + 10;

    // Pie de página
    doc.setFont("Helvetica", "bold");
    // Calcular los valores basados en ingressDetail.movement_details
    const totalSKU = ingressDetail.movement_details.length;
    const completedSKU = ingressDetail.movement_details.some(
      (detail) => detail.effective_count
    )
      ? totalSKU
      : 0;

    const totalUnidadesIngresadas1 = ingressDetail.movement_details.reduce(
      (total, detail) => total + (detail.effective_count || 0),
      0
    );

    const totalUnidadesOriginales = ingressDetail.movement_details.reduce(
      (total, detail) => total + (detail.original_count || 0),
      0
    );

    // Escribir los valores en el documento
    doc.text(
      `Total de productos: ${totalSKU} / ${completedSKU}`,
      20,
      verticalSpace
    );
    verticalSpace += 5;
    doc.text(
      `Total de unidades: ${totalUnidadesOriginales} / ${totalUnidadesIngresadas1}`,
      20,
      verticalSpace
    );

    doc.save(`Detalle_ingreso_${ingressDetail.id}.pdf`);
  };

  const handleOpenDetailModal = async (id, isConfirm) => {
    setOpenBackdrop(true);
    const result = await ingressService.getIngressById(id);
    setIngressDetail(result.data);
    setIsEdit(isConfirm);
    setOpenBackdrop(false);
    setOpenDetailMoodal(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleStatusChange = (event, newValue) => {
    setSelectedStatus(newValue);
    setFilters({ ...filters, status: newValue });
  };

  const handleCloseDetailModal = () => setOpenDetailMoodal(false);

  return (
    <Grid container component={styles.tableContainer} padding={3}>
      <Grid container item xs={6} component={styles.filtersContainer}>
        <styles.StyledAutocomplete
          options={Object.keys(statusOptions)}
          getOptionLabel={(option) => statusOptions[option]}
          renderInput={(params) => <TextField {...params} label="Estado" />}
          value={selectedStatus}
          onChange={handleStatusChange}
        />
        <Button
          style={{
            marginLeft: "15px",
            width: "50px",
            height: "40px",
            minWidth: "50px",
          }}
          variant="contained"
          color="primary"
          onClick={handleOpenFilterModal}
        >
          <FilterAltIcon />
        </Button>
        {/* <Button
          style={{
            marginLeft: "10px",
            width: "50px", 
            height: "40px",
            minWidth: "50px",
          }}
          variant="contained"
          color="primary"
        >
          <ClearIcon />
        </Button> */}

        <FilterModal
          anchorEl={anchorEl}
          onClose={handleCloseFilterModal}
          onFilter={handleFilterApply}
          initialFilters={filters}
        />
      </Grid>
      <Grid container item xs={6} component={styles.filtersContainerEnd}>
        <Link to="/crear-ingreso">
          <Button variant="contained" color="primary">
            Crear ingreso
          </Button>
        </Link>
      </Grid>
      <Grid container item xs={12}>
        <CustomMaterialTable
          title="Listado de Ingresos"
          data={incomeList}
          columns={columns}
          style={{ width: "100%", marginTop: "20px" }}
          mensajeDataArrayVacio={
            buscandoIngresos
              ? "Buscando ingresos"
              : "No hay ingresos disponibles"
          }
          options={{
            sorting: true,
            search: true,
            paging: true,
            pageSize: paginacion.pageSize,
            debounceInterval: 500,
          }}
        />
      </Grid>
      <CustomModal
        open={openDetailModal}
        onClose={handleCloseDetailModal}
        title={isEdit ? "Confirmar ingreso" : "Detalle de Ingreso"}
      >
        <div
          style={{
            overflowY: "auto",
            padding: "0px",
          }}
        >
          <ModalListadoIngresos
            ingressDetail={ingressDetail}
            handleCloseDetailModal={handleCloseDetailModal}
            isEdit={isEdit}
            fetchAllIngress={() =>
              fetchAllIngress(paginacion.page, paginacion.page_size)
            }
            setAlertObj={setAlertObj}
            setOpenSnack={setOpenSnack}
          />
        </div>
        <Grid
          style={{
            display: "flex",
            justifyContent: "end",
            marginBottom: "20px",
          }}
        >
          {!isEdit && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleDownloadPDF}
            >
              <DownloadIcon /> Descargar pdf
            </Button>
          )}
        </Grid>
      </CustomModal>
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={alertObj?.severity}
          sx={{ width: "100%" }}
        >
          {alertObj.message}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        onClick={() => setOpenBackdrop(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
};

export default ListadoIngresos;
